var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"kxVknbWcQs_NFjfVQSQpm"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";


import * as Sentry from "@sentry/nextjs";

const ignoreErrors = [
  "Cannot redefine property: googletag", // Happens when using Ad Blocker extensions and browsers like "Stands",
  // Due to a recent bug in google app for ios: https://github.com/The-Best-Codes/bestcodes-react-site/issues/65
  "ReferenceError: Can't find variable: gmo",
  "Can't find variable: gmo"
];

export const sentryBaseConfig = {
  dsn: "https://1420bf935730411a96bb58c9d6417d09@o321242.ingest.sentry.io/5278501",
  enabled: process.env.NODE_ENV === "production"
};

Sentry.init({
  ...sentryBaseConfig,
  integrations: [],
  ignoreErrors
});
