import { AspectRatio } from "@xcorejs/ui";
import RobeDoubleCarousel from "design-system/robe/Carousel/Double";
import { RobeLightboxProps } from "design-system/robe/RobeLightbox";
import { FC, useEffect, useRef, useState } from "react";
import { useGallery } from "templates/products/ProductDetail/data";
import { Skeleton } from "@mui/joy";

interface Props extends Omit<RobeLightboxProps, "index" | "white" | "images"> {
  images: [string, string, string, number][]; // [src, alt, retina, id];
}

const ImagesCarousel: FC<Props> = ({ images, ...rest }) => {
  const [openLightbox] = useGallery({ images: images.map(([,, retina]) => retina), ...rest });
  const [isSingleImageLoading, setIsSingleImageLoading] = useState<boolean>(true);
  const [loadedImages, setLoadedImages] = useState<number[]>([]);

  const singleImageRef = useRef<HTMLImageElement | null>(null);
  const imgRefs = useRef<{ [key: number]: HTMLImageElement | null }>({});

  // Check on mount if the image is already loaded from cache
  useEffect(() => {
    if (singleImageRef.current?.complete) {
      setIsSingleImageLoading(false);
    }
  }, []);

  return images.length === 1 ? (
    <AspectRatio ratio={9 / 16} position="relative">
      {isSingleImageLoading && <Skeleton style={{ position: "absolute" }} variant="rectangular" width="100%" height="100%" />} {/* Skeleton loading must be separately. If image would be the child of Skeleton, it would fetch the image twice */}

      <img
        ref={singleImageRef}
        src={images[0][0]}
        srcSet={`${images[0][0]} 1x, ${images[0][2]} 2x`}
        alt={images[0][1]}
        height="100%"
        width="auto"
        style={{ cursor: "pointer" }}
        loading="lazy"
        onClick={() => openLightbox(0)}
        onLoad={() => setIsSingleImageLoading(false)}
      />
    </AspectRatio>
  ) : (
    <RobeDoubleCarousel>
      {images.map(([src, alt, retina, id], i) => {
        const isImageInLoadedImagesArray = loadedImages.some(loadedImg => loadedImg === id);

        return (
          <AspectRatio key={i} ratio={9 / 16}>
            {!isImageInLoadedImagesArray && <Skeleton style={{ position: "absolute" }} variant="rectangular" width="100%" height="100%" />} {/* Skeleton loading must be separately. If image would be the child of Skeleton, it would fetch the image twice */}

            <img
              src={src}
              srcSet={`${src} 1x, ${retina} 2x`}
              alt={alt}
              height="100%"
              width="auto"
              loading="eager" // Do not set lazy loading, it would cause infinite Skeleton loading in Firefox
              style={{ cursor: "pointer" }}
              onClick={() => openLightbox(i)}
              ref={(el) => {
                // Only set the ref once per image
                if (el && !imgRefs.current[id]) {
                  imgRefs.current[id] = el;

                  // If the image is already loaded from cache then update state immediately.
                  if (el.complete) {
                    setLoadedImages((prev) => {
                      // Avoid duplicates.
                      if (!prev.includes(id)) {
                        return [...prev, id];
                      }
                      return prev;
                    });
                  }
                }
              }}
              onLoad={() => {
                if (!isImageInLoadedImagesArray) {
                  setLoadedImages(prevImgs => [...prevImgs, id]);
                }
              }}
            />
          </AspectRatio>
        );
      })}
    </RobeDoubleCarousel>
  );
};

export default ImagesCarousel;
